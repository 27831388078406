import React, { useEffect } from "react";

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"

const KnowledgeBasePage = () => (
  <MainContainer>
    <HeadData 
      title="Command Access Knowledge Base" 
      description="We’re always adding new content including answers to common problems."
    />

    <iframe
      title="Command Access Knowledge Base"
      src="http://commandaccess-9298297.hs-sites.com/knowledge"
      width="100%"
      height="1700"
      frameBorder="0"
      scrolling="yes"
    />
  </MainContainer>
)

export default KnowledgeBasePage